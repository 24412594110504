import React from 'react'
import { useState } from 'react'
import { HiOutlineViewBoards, HiOutlineViewGrid, HiOutlineMap } from 'react-icons/hi'
import { FaGoogle } from 'react-icons/fa'
import { IoMdColorPalette } from 'react-icons/io'
import { AiOutlineArrowRight } from 'react-icons/ai'
import { Gradient } from 'react-gradient'


export default function Input({ label, icon, type, value, onChange }) {
  return (
    <div className="flex flex-col space-y-2">
      <label htmlFor={label} className="text-gray-600 flex items-center">
        {icon}
        <span className="ml-2">{label}</span>
      </label>
      <div className="relative">
        <input
          id={label}
          type={type}
          value={value}
          onChange={onChange}
          className="w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
    </div>
  )
}
import React from 'react'
import { useState } from 'react'
import { HiOutlineViewBoards, HiOutlineViewGrid, HiOutlineMap } from 'react-icons/hi'
import { FaGoogle } from 'react-icons/fa'
import { IoMdColorPalette } from 'react-icons/io'
import { AiOutlineArrowRight } from 'react-icons/ai'
import { Gradient } from 'react-gradient'


export default function SegmentedControl({ label, icon, value, onChange, options }) {
  const handleClick = (optionName) => {
    onChange(optionName);
  }

  return (
    <div className="flex flex-col space-y-2">
      <label className="text-gray-600 flex items-center">
        {icon}
        <span className="ml-2">{label}</span>
      </label>
      <div className="flex items-center space-x-2 rounded-md overflow-hidden">
        {options.map((option) => (
          <button
            key={option.name}
            type="button"
            className={`px-5 p-2 rounded-md focus:outline-none text-center ${value === option.name ? 'bg-indigo-600 text-white' : 'bg-white text-gray-700'} border border-gray-300`}
            onClick={() => handleClick(option.name)}
          >
            {option.icon}{option.name}
          </button>
        ))}
      </div>
    </div>
  )
}
import React, { useState } from 'react';
import { HiOutlineViewBoards, HiOutlineViewList, HiOutlineTable } from 'react-icons/hi';
import { FaGoogle } from 'react-icons/fa';
import { IoMdColorPalette } from 'react-icons/io';
import { AiOutlineArrowRight, AiOutlineQuestionCircle } from 'react-icons/ai';
import { Gradient } from 'react-gradient';
import axios from 'axios';

import Input from './components/Input.js';
import SegmentedControl from './components/SegmentedControl.js';

const templates = [
  { name: 'Table', icon: <HiOutlineTable className="w-6 h-6" /> },
  { name: 'List', icon: <HiOutlineViewList className="w-6 h-6" /> },
  { name: 'Cards', icon: <HiOutlineViewBoards className="w-6 h-6" /> },
];
const themes = [
  { name: 'Light', bg: 'bg-white', text: 'text-gray-900' },
  { name: 'Dark', bg: 'bg-gray-900', text: 'text-white' },
];

export default function App() {
  const [url, setUrl] = useState('');
  const [template, setTemplate] = useState(templates[0].name);
  const [theme, setTheme] = useState(themes[0].name);
  const [mapApiKey, setMapApiKey] = useState('');

  const gradientColors = [
    ['#FBBF24', '#F472B6'],
    ['#C7D2FE', '#6E7BFF'],
    ['#FBBF24', '#F472B6'],
    ['#C7D2FE', '#6E7BFF'],
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    const spreadsheetId = url.match(/[-\w]{25,}/);
    const csvUrl = `https://docs.google.com/spreadsheets/d/${spreadsheetId}/export?gid=0&format=csv`;
    try {
      const response = await axios.get(csvUrl);
      const csvHead = response.data.split('\n').slice(0, 5);
      const postData = {
        SPREADSHEET_ID: spreadsheetId,
        SPREADSHEET_SCHEMA: csvHead,
        VIEW_TYPE: template
      };
      const form = document.createElement('form');
      form.method = 'GET';
      form.action = 'https://react.gitwit.dev/template/gsheets-table';
      form.target = '_blank';
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = 'variables';
      input.value = JSON.stringify(postData);
      form.appendChild(input);
      document.body.appendChild(form);
      form.submit();
    } catch (error) {
      alert("I can't fetch that spreadsheet! Make sure that sharing is set to public.");
    }
  };

  const handleSupport = () => {
    window.location.href = 'mailto:support@gitwit.dev';
  };

  return (
    <Gradient
      gradients={gradientColors}
      property="background"
      duration={10000}
      angle="45deg"
      className="flex justify-center items-center h-screen"
    >
      <div className="w-full max-w-md">
        <form onSubmit={handleSubmit} className="bg-white p-6 rounded-md shadow-md space-y-4">
          <h1 className="text-2xl font-semibold mb-6">Create my app from a Google Sheet</h1>
          <p className="text-gray-700 mb-4">Use this tool to quickly create an app from a Google Sheet using AI</p><p>Ensure that the header columns are in the first row of the Google Sheet, and that the Google Sheet is public.</p>
          <div className="space-y-2">
            <Input label="Google Sheets URL" icon={<FaGoogle className="w-6 h-6" />} type="text" value={url} onChange={(e) => setUrl(e.target.value)} />
          </div>
          <div className="space-y-2">
            <SegmentedControl label="Template" icon={<HiOutlineTable className="w-6 h-6" />} value={template} onChange={setTemplate} options={templates} />
          </div>
          <div className="space-y-2">
            <SegmentedControl label="Theme" icon={<IoMdColorPalette className="w-6 h-6" />} value={theme} onChange={setTheme} options={themes} />
          </div>
          <div className="flex items-center justify-center">
            <button type="submit" className="mt-6 flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Create my app
              <AiOutlineArrowRight className="ml-2 w-6 h-6" />
            </button>
          </div>
          <div className="flex items-center justify-center">
            <button type="button" onClick={handleSupport} className="flex items-center text-gray-500 hover:text-gray-700">
              <AiOutlineQuestionCircle className="w-6 h-6 mr-2" />
              <span>Get Help</span>
            </button>
          </div>
        </form>
      </div>
    </Gradient>
  );
}